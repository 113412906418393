<template>
  <sgv-table
    :items="orderMapped"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'other'">
      <div class="mb-3">
        <DetailOrderDepositInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderDepositInput>

        <DetailOrderDiscountInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderDiscountInput>

        <DetailOrderExpenseInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderExpenseInput>

        <DetailOrderVatPriceInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderVatPriceInput>

        <DetailOrderChangeErrorInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderChangeErrorInput>

        <DetailOrderPaymentInput
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :contactId="formData.contactId"
          :configs="configs"
          :orders="orders">
        </DetailOrderPaymentInput>
      </div>
    </template>

    <template slot="option" v-if="options.toolbar === 'creditNote'">
      <DetailOrderCreditNoteAvailable
        ref="orderCreditNoteAvailable"
        class="mb-3"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderCreditNoteAvailable>
    </template>

    <template slot-scope="row">
      <DetailOrderSubItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="row"
        v-model="selectedRows"
        :canDestroy="!formData.approvedAt">
      </DetailOrderSubItem>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { round } from 'lodash'
import DetailOrderDepositInput from './DetailOrderDepositInput.vue'
import DetailOrderDiscountInput from './DetailOrderDiscountInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderPaymentInput from './DetailOrderPaymentInput.vue'
import DetailOrderVatPriceInput from './DetailOrderVatPriceInput.vue'
import DetailOrderChangeErrorInput from './DetailOrderChangeErrorInput.vue'
import DetailOrderCreditNoteAvailable from './DetailOrderCreditNoteAvailable'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER, LIST_ORDER_CONFIG,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
  LIST_ORDER_CREDIT_NOTE
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailOrder`,
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      configs: [],
      selectedRows: [],
      creditNotes: []
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      const arr = []

      if (this.formData.approvedAt) return arr

      arr.push({value: 'other', icon: 'plus', class: 'text-success'})

      if (this.creditNotes.length > 0) {
        arr.push({value: 'creditNote', icon: 'file-download', class: 'text-success'})
      }

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      const total = this.orders
      .flatMap(v => v.ledgers)
      .reduce((t,v) => t += +v.amount, 0)

      return round(total, 2)
    },
    orderMapped () {
      return [
        ...this.orders.filter(v => v.type === 'deposit'),
        ...this.orders.filter(v => v.type === 'discount'),
        ...this.orders.filter(v => v.type === 'expense'),
        ...this.orders.filter(v => v.type === 'creditNote'),
        ...this.orders.filter(v => v.type === 'vatPrice'),
        ...this.orders.filter(v => v.type === 'withholdingPrice'),
        ...this.orders.filter(v => v.type === 'changeError'),
        ...this.orders.filter(v => v.type === 'payment'),
      ]
    }
  },
  apollo: {
    configs: {
      query () {
        return LIST_ORDER_CONFIG(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.formData.contactId
        }
      },
      fetchPolicy: 'network-only'
    },
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            this.refetchAll()
            const newOrder = subscriptionData.data.orderCreated
            const orders = [...previous.orders, newOrder]
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === updatedOrder.id)
            if (idx !== -1) {
              orders[idx] = updatedOrder
            }
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            this.refetchAll()
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)
            if (idx !== -1) {
              orders.splice(idx, 1)
            }
            return {...previous, orders}
          }
        }
      ]
    }
  },
  methods: {
    debitNoteList () {
      this.$apollo.query({
        query: LIST_ORDER_CREDIT_NOTE(this.templateType),
        variables: {
          docType: this.docType,
          contactId: this.formData.contactId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.creditNotes = res.data.orders
      })
      .catch(() => {
        this.creditNotes = []
      })
    },
    refetchAll () {
      if (this.options.toolbar === 'creditNote') this.$refs.orderCreditNoteAvailable.refetchList()
      if (this.options.toolbar === 'payable') this.$refs.orderPayableAvailable.refetchList()
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  created () {
    this.debitNoteList()
  },
  components: {
    DetailOrderPaymentInput,
    DetailOrderSubItem,
    DetailOrderDepositInput,
    DetailOrderChangeErrorInput,
    DetailOrderCreditNoteAvailable,
    DetailOrderVatPriceInput,
    DetailOrderDiscountInput,
    DetailOrderExpenseInput,
  }
}
</script>

<style lang="css">
</style>
